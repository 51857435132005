@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display&display=swap");

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background: #F5F5F5; */
  background: #fff;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

/* a:hover {
    color: rgb(195, 182, 182);
} */

.pages {
  padding: 4rem 0;
}

.pages h2 {
  text-align: center;
  font-family: "Playfair Display", serif;
}

.slick-prev {
  left: 20px;
  z-index: 2;
}

.slick-next {
  right: 25px;
}

.slick-next,
.slick-prev {
  width: 25px;
  height: 25px;
  padding-top: 4px;
  background: var(--btn-bg-color);
  color: #fff;
  border-radius: 50%;
}

.slick-next:hover,
.slick-prev:hover {
  background: var(--btn-bg-color);
  color: #fff;
}

.mobile-book-btn {
  display: none;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  margin-top: 1rem;
}

/* Navbar Start  */

.custom-btn-div {
  display: block;
  margin: 1rem 0;
}

.custom-btn {
  display: inline-flex;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  background: var(--btn-bg-color);
  color: #fff;
  /* margin: 2rem 0; */
}

.custom-btn:hover {
  color: #fff;
  background: var(--btn-bg-hover);
}

.section {
  padding: 2.5rem 0;
}

.section-heading {
  color: #8a1412;
  text-align: center;
  font-family: Playfair Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.375rem;
  /* 118.75% */
  text-transform: uppercase;
}

.section-para {
  color: #343434;
  text-align: center;

  /* Body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  padding-bottom: 2rem;
  /* 150% */
}

/* Banner Start  */

.banner {
  height: 100vh;
  width: 100%;
}

.banner .carousel {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
}

/* .banner .carousel .carousel-caption {
    position: absolute;
    left: 50%;
    top: 65%;
    color: #fff;
    text-align: center;
    translate: -50% -50%;
    width: 100%;
    padding: 0 18rem;
} */

.banner .carousel-caption p {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  /* background: rgba(28, 21, 8, 0.30); */
  /* line-height: 1rem; */
  /* 66.667% */
  margin-bottom: 1rem;
}

.home-banner-carousel .image_wrapper {
  position: relative;
  height: 100%;
}

.home-banner-carousel .overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex !important;
  flex-direction: column;
}

.home-banner-carousel .overlay_2 {
  inset: 0;
}

/* .banner .carousel-caption p span,
.banner .carousel-caption h2 span {
    background: rgba(28, 21, 8, 0.30);
    padding: 0.5rem;
} */

.banner .carousel-caption h2 {
  color: #fff;
  text-align: center;
  font-family: Playfair Display;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  /* 100% */
  letter-spacing: 0.06rem;
}

/* About section start  */

.container-wrapper {
  background: #ffffff;
}

.room-price-label {
  color: #282828;
  text-align: center;

  /* Body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: 1rem;
  margin-left: 1rem;
  /* 150% */
}

.about-text {
  padding: 1rem 2rem;
  text-align: justify;
}

/* .about-img-wrapper{
    display: flex;
}

.about-inr-img-div {
    width: 500px;
}

.about-lower-img-div{
    width: max-content;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    margin-left: -7rem;
    margin-top: 2rem;
    z-index: 8;
}

.about-lower-img-div img{
    max-width: 215px;
} */

.about-inr-img-div {
  height: 100%;
}

.image-div {
  /* height: 100%; */
  max-height: 35rem;
  padding: 1rem;
  /* border: 1px solid; */
}

.image-slider {
  width: 100%;
  height: 100%;
  /* border: 3px solid; */
}

.image-slider div {
  width: 100%;
  height: 100%;
}

.image-slider .slick-slide {
  width: 100%;
  height: 100%;
}

.image-slider .slick-slide .slick-list {
  height: 100%;
}

.image-slider .slick-slide .slick-list .slick-track {
  height: 100% !important;
  width: 100%;
}

.image-slider .slick-slider div {
  height: 100%;
}

.image-slider .slick-slide img {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lower-image-div {
  width: 250px;
  position: absolute;
}

.lower-image-div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .about-section .slick-next {
    right: 15px;
}


.about-section .slick-prev {
    left: 15px;
    z-index: 2;
} */

/* 
.image-div .carousel,
.image-div .carousel-inner,
.image-div .carousel-inner .carousel-item {
    height: 100%;
}

.image-div .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: 100%;
} */

.about-text h2 {
  color: #8a1412;
  /* text-align: center; */

  /* Header */
  font-family: Playfair Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.375rem;
  /* 118.75% */
}

.about-text p {
  color: #282828;
  text-align: justify;

  /* body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */

  /* display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}

/* Room section start  */

/* .room-section .slick-prev{
    left: 20px;
    z-index: 2;
}

.room-section .slick-next{
    right: 25px;
} */

.phone-row {
  background: #ececec;
}

.text-div {
  padding: 4rem 2rem 2rem 4rem;
}

.text-div h2 {
  color: #8a1412;

  /* Header */
  font-family: Playfair Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.375rem;
  /* 118.75% */
}

.text-div p {
  color: #343434;
  text-align: justify;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
  padding: 1rem 0;
}

.room-facility-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #282828;
  border-bottom: 1px solid #282828;
  text-align: center;
  gap: 5px;
  /* margin-top: 1rem; */
  padding: 0.5rem;
}

.room-facility-div span {
  color: #222;
  text-align: center;
  /* leading-trim: both;
    text-edge: cap; */
  font-family: Montserrat;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 200% */
}

/* Facility Section Start  */

.wrapper-div {
  padding: 2rem 3rem;
  background: #fff;
  height: 500px;
}

.facilities-img-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 1rem;
}

.facilities-img-div span {
  margin-top: -100px;
  /* border: 1px solid; */
  position: absolute;
  transform: translate(-50%);
  padding: 0.5rem 2rem;
  background: rgba(48, 48, 48, 0.3);
  color: #fff;

  /* Header */
  font-family: Playfair Display;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  /* 116.667% */
  text-transform: uppercase;
}

/* .wrapper-div .slick-prev {
    left: 20px;
    z-index: 2;
}

.wrapper-div .slick-next {
    right: 25px;
} */

/* Nearby-places start  */

.nearby-places {
  padding: 2rem 0;
  background: #d9d9d9;
}

.nearby-places .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
}

.nearby-places .slider-caption {
  /* width: 100%; */
  background: #fff;
  text-align: center;
  padding: 1rem;
}

.nearby-slider {
  padding: 0 1rem;
}

.nearby-slider img {
  height: 400px !important;
}

/* .wrapper-div-nearby{
    height: 400px;
} */

/* .wrapper-div-nearby .slick-prev {
    left: 20px;
    z-index: 2;
}

.wrapper-div-nearby .slick-next {
    right: 25px;
} */

/* Testimonial Section Start  */

.testimonial-section {
  background: #fff;
}

.testimonial-wrapper {
  padding: 3rem 0;
  background: #fff;
}

.testimonial-main {
  margin-top: 2rem;
}

.testimonial-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 12rem;
}

.testimonial-div p {
  color: #282828;
  text-align: center;

  /* Body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.testimonial-div span {
  color: #8a1412;
  text-align: center;

  /* Body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

/* offers-section start  */

.offers-section {
  background: #ececec;
  margin: 2rem 0;
}

.offers-section p {
  color: #8a1412;
  /* Header */
  font-family: Playfair Display;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.375rem;
  /* 118.75% */
}

.email-div {
  display: flex;
  align-items: flex-end;
}

.offers-section input {
  outline: 0;
  border: 0;
  border-bottom: 1px solid #282828;
  width: 100%;
  background: transparent;
}

.offer-text {
  display: flex;
  align-items: flex-end;
}

.subscribe-div {
  display: flex;
  justify-content: flex-end;
}

.subscribe-btn {
  background: var(--btn-bg-color);
  color: #fff;
  padding: 1rem 3rem;
}

.subscribe-btn:hover {
  color: #fff;
  background: var(--btn-bg-hover);
}

/* Footer Start  */

.footer {
  background: #fff;
  padding-top: 1rem;
}

.mobile-foot-accordian {
  display: none;
}

.mobile-foot-accordian .accordion-item .accordion-button {
  color: #8a1412;
  font-family: Playfair Display;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  text-transform: uppercase;
}

.mobile-foot-accordian .accordion-item .accordion-button:not(.collapsed) {
  background: transparent;
}

.mobile-foot-accordian .accordion-item .accordion-button:focus {
  box-shadow: none;
}

.footer h4 {
  color: #8a1412;
  /* text-align: center; */

  /* Header */
  font-family: Playfair Display;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  /* 116.667% */
  text-transform: uppercase;
}

.footer a {
  color: #282828;

  /* Body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.address-div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.address-div img {
  width: 150px;
}

.foot-btn-div {
  display: block;
  width: 100%;
  height: 100%;
  margin: 2rem 0;
  /* padding: 0.5rem; */
}

.direction-btn {
  background: var(--btn-bg-color);
  color: #fff !important;
  padding: 1rem 3rem;
}

.direction-btn:hover {
  color: #fff;
  background: var(--btn-bg-hover);
}

.footer-bottom {
  padding: 1rem 0;
  background: #1c1508;
  color: #fff;
}

.footer-bottom a {
  color: #fff;
  margin-right: 5px;
}
.contact-icons {
  color: var(--icon-bg-color);
}
.upper-scroll-btn {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #7eae35;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 2%;
  right: 2%;
  color: white;
  .icon-up {
    animation: updown 0.8s linear infinite alternate;
  }
}
@keyframes updown {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(-1rem);
    opacity: 0;
  }
}

/* .foot-links{
    align-items: flex-end;
} */

.social-div {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
}
