@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display&display=swap");

header {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

/* .sticky-navbar {
    position: fixed;
    top: 0; 
    width: 100%;
    z-index: 5;
} */

.nav-custom-btn {
  display: inline-flex;
  padding: 0.2rem 1rem;
  justify-content: center;
  align-items: center;
  background: var(--btn-bg-color);
  color: #fff;
  &:hover {
    background-color: var(--btn-bg-hover);
    color: var(--icon-bg--color);
  }
}

.navbar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  /* height: 80px; */
  color: white;
}

.upper-navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* padding: 1rem; */
}

.uper-nav-links {
  display: flex;
  gap: 5%;
  width: 60%;
  justify-content: center;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10%;
  border-top: 0.5px solid #bbbbbb;
  padding: 10px;
}

.navbar-brand img {
  width: 130px;
}

.number {
  color: #363636;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
}

.uper-nav-links .nav-link a {
  padding: 0 0.5rem;
  border-radius: 5px;
  transition: all 0.3s;
  color: #363636;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: capitalize;
}

.uper-nav-links .nav-link a:hover {
  background-color: var(--btn-bg-color);
  color: #fff;
  padding: 0.5rem;
}

.lower-nav {
  display: flex;
  flex-shrink: 0;
  height: 2.625rem;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.lower-nav-link {
  color: #8a1412;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  display: flex;
  gap: 5px;
  align-items: center;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.mobile-phone-icon {
  display: none;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .uper-nav-links {
    gap: 2%;
    width: 65%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    background: #fff;
    transform: translateX(-200vw);
    z-index: 3;
    flex-direction: column;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .mobile-phone-icon {
    display: block;
  }

  .laptop-phone-icon {
    display: none;
  }

  .lower-nav-link {
    display: none;
  }

  .uper-nav-links {
    width: 100%;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .nav-book {
    display: none;
  }

  .nav-close-btn .fa-xmark {
    color: #8a1412;
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  .nav-close-btn .fa-xmark {
    color: #8a1412;
    font-size: 2rem;
  }

  .uper-nav-links {
    width: 100%;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .mobile-phone-icon {
    display: block;
  }

  .laptop-phone-icon {
    display: none;
  }

  .lower-nav-link {
    display: none;
  }

  .nav-book {
    display: none;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: #fff;

    transition: 1s;
    transform: translateX(-100vw);
    z-index: 3;
    flex-direction: column;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}
