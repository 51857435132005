:root {
  --btn-bg-color: #85b434;
  --btn-bg-hover: rgb(120, 166, 42);
  --icon-bg-color: #744729;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.feature-icon-container {
  background-color: #a0c6362c;
  height: 150px;
  border-radius: 4px;
  /* width: 150px; */
}

.feature-icon {
  color: var(--icon-bg-color);
}
