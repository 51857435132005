/* body {
    background: #ECECEC;
} */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display&display=swap");

.about-banner {
  height: 100vh;
}

.pages-banner {
  width: 100%;
  height: 80vh;
}

/* .pages-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.pages-banner {
  margin: 0 auto;
  height: 100%;
}

.pages-banner ul {
  list-style: none;
  gap: 20px;
  font-weight: bolder;
  padding: 0;
  height: 100%;
}

.pages-banner .image_wrapper {
  position: relative;
  height: 100%;
}

.pages-banner img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.pages-banner .overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pages-banner .overlay_2 {
  inset: 0;
}

.pages-banner .banner-heading {
  position: absolute;
  z-index: 2;
  padding: 1rem;
  top: 55%;
  left: 50%;
  transform: translate(-50%);
  /* background: rgba(28, 21, 8, 0.50); */
  color: #fff;

  font-family: Playfair Display;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.1875rem;
}

/* .pages-banner h1 {
    top: 50%;
} */

.about-page .image-slider .slick-slide img {
  object-fit: fill;
}

.aboutPage-facility {
  background-color: #ececec;
  padding: 2rem 0;
  margin: 2rem 0;
}

.aboutPage-facility-inner .row {
  margin: 2rem 0;
}

.about-facility {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.about-btn-div {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
}

.aboutPage-facility .mobile-facility-slider {
  display: none;
}

.aboutPage-facility .mobile-facility-slider .about-facility-mobile {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.space-management p {
  margin: 0;
  padding: 0;
}

/* .slick-prev {
    left: 20px;
    z-index: 2;
}

.slick-next {
    right: 25px;
} */

.section-find {
  margin: 2rem 0;
}

.section-find h2 {
  margin: 3rem 0;
}

.findcard-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 20rem;
}

.findcard-wrapper .image-slider {
  height: 100%;
  gap: 20px;
}

.findcard-wrapper .image-slider div {
  width: 100%;
  height: 100%;
  gap: 20px;
  display: flex;
}

.findcard {
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  background: #ececec;
  height: 100%;
}

.findcard h6 {
  color: #282828;
  text-align: center;
  margin: 1rem 0;
  /* Sub Header */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  /* 125% */
}

.findcard p {
  color: #282828;
  text-align: center;

  /* Body */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.findcard-wrapper .thingstodo-wrapper {
  background-color: #fff;
  padding: 2rem;
}

.thingstodo-wrapper {
  background-color: #ececec;
  padding: 2rem;
}

.thingstodo-wrapper .nearby-slider .slider-caption {
  text-align: center;
  padding: 0.5rem 0;
  background-color: #fff;
}

.thingstodo-wrapper .nearby-slider .slider-caption span {
  color: #8a1412;
  text-align: center;

  font-family: "Playfair Display";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .pages-banner .banner-heading {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .about-banner {
    height: auto;
  }

  .pages-banner {
    height: 50vh;
  }

  .pages-banner .banner-heading {
    top: 50%;
    font-size: 1.1rem;
    padding: 0.5rem;
  }

  .section-heading {
    font-size: 1.7rem;
    margin-top: 0.5rem;
  }

  .aboutPage-row {
    flex-direction: column-reverse;
  }

  .aboutPage-facility .aboutPage-facility-inner {
    display: none;
  }

  .aboutPage-facility .mobile-facility-slider {
    display: block;
  }
}
