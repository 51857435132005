/* .checks-wrapper {
    margin-top: -2rem;

} */

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display&display=swap");

.checks-main {
  padding: 2rem 0;
}

.checks-wrapper {
  padding: 1rem 2rem;
}

.checkig-row {
  padding: 1rem 2rem;
  overflow: hidden;

  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.checkig-row input {
  border: 0;
  width: 100%;
  overflow: hidden;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  width: 100%;

} */

input[type="date"]::-webkit-calendar-picker-indicator {
  /* display: none; */
  opacity: 0;
  width: 50%;
}

/* Style the input field to make it clickable */
input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.checking-col {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  /* border: 1px solid; */
}

.cheks-input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.check-book-div {
  display: flex;
  justify-content: center;
}

.cheks-input-div .dropdown-menu.show {
  transform: translate3d(-28px, 40px, 0px);
}

.cheks-input-div .dropdown .dropdown-toggle {
  background: transparent;
  color: black;
  border: 0;
  padding: 0;
}

.count-drop-div {
  width: 215px;
}

.count-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.8rem 0;
}

.count-div .counter {
  display: flex;
}

.count-div .counter button {
  width: 35px;
  border: 0;
  background-color: red;
  color: #fff;
  font-weight: 700;
}

.count-div .counter span {
  width: 35px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .checks-main {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .checking-col {
    margin: 0.8rem 0;
  }

  .checks-wrapper {
    display: none;
  }

  .checks-main {
    display: none;
  }
}
